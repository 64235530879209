.confirm{
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
}

.confirm .confirm-box{
    width: 500px;
    background-color: var(--white-color);
    padding: 40px 60px;
    border-radius: 5px;
}

.confirm .confirm-box h1{
    font-size: 24px;
    font-family: var(--font-primary, Inter);
    font-weight: 600;
    color: var(--black, #101A24);
    margin-bottom: 36px;
}

.confirm .confirm-box .confirm-btn{
    display: flex;
    justify-content: flex-start;
    flex-direction: row-reverse;
    align-items: center;
}

.confirm .confirm-box .confirm-btn button{
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    outline: none;
    cursor: pointer;
    margin-left: 10px;
    font-size: 16px;
    font-family: var(--font-primary, Inter);
    font-weight: 500;
}

.confirm .confirm-box .confirm-btn #delete{
    background-color: var(--red, #EB5757);;
    color: var(--white-color);
    transition: all 0.3s ease-in-out;
}

.confirm .confirm-box .confirm-btn #delete:hover{
    background-color: var(--red-hover, red);
}

.confirm .confirm-box .confirm-btn #cancel{
    background-color: var(--black, #585859);
    color: var(--white-color);
    transition: all 0.3s ease-in-out;
}

.confirm .confirm-box .confirm-btn #cancel:hover{
    background-color: var(--black-hover, #3a3a3a);
}

