.add-new-item button{
    position: relative;
    display: flex;
    padding: 10px 25px 10px 15px;
    align-items: center;
    border-radius: 8px;
    background: var(--secondary-color, #FF9400);
    color: var(--white-color, #ffffff);
    border: none;
    font-size: 16px;
    font-family: var(--font-primary, Inter);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.add-new-item button:hover{
  background-color: var(--hover-color, #EA6D27);
}

.add-new-item .plus{
  width: 19px;
  height: 19px;
}