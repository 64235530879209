
.dashboard-buttons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 60px;
    margin-inline: 40px;
}

.dashboard-left-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    gap: 10px;
}

.refresh-button button {
    display: flex;
    padding: 10px 15px 10px 25px;
    align-items: center;
    gap: 5px;
    border-radius: 8px;
    border: none;
    color: var(--secondary-color);
    font-size: 16px;
    font-family: var(--font-primary);
    background-color: var(--white, #FFFFFF);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}
.refresh-button button:hover {
    background-color: var(--gray-light, #F4F4F4);
    color: var(--gray-color);
}
.refresh-button .refresh-icon {
    width: 18px;
    height: 18px;
}


@media screen and (max-width: 768px) {
    .refresh-button p {
        display: none;
    }
    .refresh-button button {
        padding: 10px;
    }
}