.our-story-section {
    padding-top: 128px;
    padding-bottom: 256px;
    background-color: var(--primary-color);
}

.our-story-box .our-story-img {
    z-index: -1;
}

.our-story-box .our-story-img .img {
    /* width: 330px; */
    width: 21.5vw;
    height: auto;
}

.our-story-box .our-story-content {
    /* width: 512px; */
    width: 43.5%;
    height: auto;
    margin-left: -7vw;
    margin-top: -8px;
    background: var(--white-color);
    box-shadow: 0px 4px 25px rgba(107, 116, 131, 0.15);
    border-radius: 10px;
}

.our-story-box .our-story-content .section-heading .subtitle {
    margin-top: 0;
}
.our-story-box .our-story-content .section-heading .title {
    margin-bottom: 0;
    margin-top: 44px;
}

.our-story-box .our-story-content .our-story-text {
    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    text-align: center;
    color: var(--gray-color);
    padding-top: 34px;
    margin: 0;
}

.our-story-box .our-story-content .our-story-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--secondary-color);
    border-bottom: 3px solid var(--secondary-color);
    border-radius: 2px;
    cursor: pointer;
    padding-top: 40px;
    transition: all 0.3s ease-in-out;
    width: fit-content;
}

.our-story-box .our-story-content .our-story-btn p {
    font-family: var(--font-primary);
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: 0.01em;
    color: var(--secondary-color);
    text-align: center;
    text-decoration: none;
    margin: 0;
    transition: all 0.3s ease-in-out;
}

.our-story-box .our-story-content .our-story-btn:hover {
    border-bottom: 3px solid var(--hover-color);
}
.our-story-box .our-story-content .our-story-btn:hover p {
    color: var(--hover-color);
}

/* Background images */
.our-story-section .backgroung-img1 {
    position: absolute;
    margin-top: -500px;
    margin-left: -6%;
    /* transform: matrix(0.99, 0.17, -0.21, 0.98, 0, 0); */
    transform: rotate(9.75deg);
    z-index: -1;
}

.our-story-section .backgroung-img1 .img {
    width: 426px;
    height: auto;
}

/*  */
.our-story-section .backgroung-img2 {
    position: absolute;
    margin-top: 540px;
    margin-left: 31%;
    z-index: -99;
}

.our-story-section .backgroung-img2 .img {
    width: 276px;
    height: auto;
}

/* Media Query for the Responsiveness */

/* Mobile */
@media screen and (max-width: 768px) {
    .our-story-section {
        padding-top: 90px;
        padding-bottom: 30px;
        background-color: var(--white-color);
    }
    .our-story-box .our-story-img, .our-story-section .backgroung-img1, .our-story-section .backgroung-img2 {
        display: none;
    }
    .our-story-box .our-story-content {
        width: 100%;
        height: auto;
        margin: 0;
        background: none;
        box-shadow: none;
    }
    .our-story-box .our-story-content .section-heading .title {
        margin-top: 24px;
    }
    .our-story-box .our-story-content .our-story-text {
        padding-top: 30px;
        font-size: 14px;
        line-height: 25px;
    }
    .our-story-box .our-story-content .our-story-btn {
        padding-top: 30px;
    }
    .our-story-box .our-story-content .our-story-btn p {
        font-size: 18px;
    }
}

/* Tablets */
@media (min-width: 768px) and (max-width: 992px) {
    .our-story-section {
        padding-top: 40px;
        padding-bottom: 180px;
    }
    .our-story-box .our-story-img, .our-story-section .backgroung-img1 {
        display: none;
    }
    .our-story-box .our-story-content {
        width: 100%;
        height: auto;
        margin: 0;
    }
    .our-story-section .backgroung-img2 {
        margin-top: 370px;
        margin-left: 61%;
    }
}

/* Small Laptop & Desktop */
@media (min-width: 992px) and (max-width: 1280px) {
    .our-story-box .our-story-img .img .img {
        width: 260px;
        height: auto;
    }
    .our-story-section .backgroung-img1 {
        margin-top: -432px;
        margin-left: -5%;
    }
}