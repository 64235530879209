.special-dishes-container {
    background-color: var(--primary-color);
    padding-top: 100px;
    padding-bottom: 128px;
}

.special-dishes {
    padding-top: 70px;
}

.special-dishes-card {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    transition: all 0.3s ease-in-out;
}

.special-dishes-card img {
    height: 208px;
    width: 208px;
    border-radius: 50%;
    /* position: absolute; */
    margin-bottom: -100px;
    z-index: 99;
}

.special-dishes-price {
    height: 54px;
    width: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--black-color);
    border-radius: 50%;
    position: absolute;
    z-index: 99;
    margin-left: 196px;
    margin-top: 52px;
    box-shadow: 0px 15px 30px rgb(16, 26, 36, 0.25);

}

.special-dishes-price p {
    font-family: var(--font-primary);
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    letter-spacing: 0em;
    text-align: center;
    color: var(--white-color);

}

.special-dishes-box {
    width: 266px;
    height: 310px;
    border: 3px solid #fff;
    border-radius: 50px 10px 50px 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    z-index: 0;
    transition: all 0.3s ease-in-out;
}

.special-dishes-card:hover .special-dishes-box {
    background-color: var(--white-color);
    box-shadow: 0px 4px 25px rgba(92, 101, 116, 0.1)
}

.special-dishes-content {
    padding: 0 24px;
    margin-top: 116px;
}

.special-dishes-box h2 {
    font-family: var(--font-heading);
    font-size: 22px;
    font-weight: 700;
    line-height: 26px;
    text-align: center;
}

.special-dishes-box p {
    font-size: 15px;
    font-weight: 400;
    line-height: 26px;
    text-align: center;
    padding-top: 10px;
    color: var(--gray-color);
    font-family: var(--font-primary);
}

/* BG Images */
.special-dishes-container .backgroung-img1 img {
    height: 212px;
    width: 224px;
}

.special-dishes-container .backgroung-img1 {
    position: absolute;
    margin-top: 60px;
    margin-left: 18.5%;
    z-index: -1;
}

.special-dishes-container .backgroung-img2 img {
    height: 258px;
    width: 180px;
}

.special-dishes-container .backgroung-img2 {
    position: absolute;
    margin-top: 74px;
    margin-left: 66%;
    z-index: -1;
}

.special-dishes-container .backgroung-img3 img {
    height: 124px;
    width: auto;
}

.special-dishes-container .backgroung-img3 {
    position: absolute;
    margin-top: 44%;
    margin-left: 74%;
    z-index: -1;
}

/* Mobile */
@media screen and (max-width: 768px) {
    .special-dishes {
        padding-top: 24px;
    }
    .special-dishes-container .backgroung-img1, .special-dishes-container .backgroung-img2, .special-dishes-container .backgroung-img3 {
        display: none;
    }
    .special-dishes-container {
        padding-top: 90px;
        padding-bottom: 128px;
    }
}

/* Tablets */
@media (min-width: 768px) and (max-width: 992px) {
    .section-heading p {
        width: 45%;
    }
    .special-dishes-container .backgroung-img1 {
        display: none;
    }
    .special-dishes-container .backgroung-img2 {
        margin-top: 163px;
        margin-left: 69%;
    }
    .special-dishes-container .backgroung-img3 {
        margin-top: 127%;
        margin-left: 67%;
    }
}