.testimonial-container {
    padding-bottom: 160px;
    background-color: var(--primary-color);
    position: relative;
  }

  .icon-wrapper {
    padding: 0 270px;
    padding-top: 20px;
  }
  .icon-wrapper .prev-icon {
    font-size: 40px;
    color: rgba(107, 116, 131, 0.4);
  }
  .icon-wrapper .next-icon {
    font-size: 40px;
    color: rgba(107, 116, 131, 0.4);
    transform: rotate(180deg);
  }
  .indicator {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: rgba(107, 116, 131, 0.4);
    margin: 0 10px;
    cursor: pointer;
    transition: all 0.3s;
  }
  
  .indicator.active {
    width: 24px;
    border-radius: 5px;
    background-color: var(--secondary-color);
    margin: 0 10px;
  }

  .testimonial-container #background-img1 img {
    width: 224.2px;
    height: 212.7px;
    opacity: 0.1;
  }
  
  .testimonial-container #background-img1 {
    position: absolute;
    top: 7%;
    right: 200px;
    z-index: -1;
  }
  
  .testimonial-container #background-img2 img {
    width: 408px;
    height: 289.91px;
  } 
  
  .testimonial-container #background-img2 {
    position: absolute;
    bottom: -3%;
    left: -90px;
    z-index: -1;
  }
  
  .testimonial-container .each-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    margin-top: 30px;
  }
  
  .testimonial-container .each-slide .testimonial-card-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 20px;
  }
  
  .testimonial-container .each-slide .testimonial-card-container .testimonial-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin-bottom: -50px;
    z-index: 2;
  }
  
  .testimonial-container .each-slide .testimonial-card-container .testimonial-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  @media screen and (max-width: 835px) and (min-width: 769px) {

    .testimonial-container{
        height: 100%;
        margin-bottom: 0;
    }

    .testimonial-container #background-img2, .dot , .icon-wrapper{
      display: none;
    }

    .testimonial-container .section-heading{
        margin-top: 0;
        margin-bottom: 70px;
    }
    .testimonial-container .testimonial-card-container{
        margin-bottom: 0;
    }

    .testimonial-container #background-img1 {
      right: 0;
      top: 9%;
    }
}

@media screen and (max-width: 1280px) and (min-width: 836px){
    .testimonial-container{
        height: 100%;
        margin-bottom: 10px;
    }

    .testimonial-container .section-heading{
        margin-top: 0;
        margin-bottom: 20px;
    }
    .testimonial-container .testimonial-card-container{
        margin-bottom: 10px;
    }

    .icon-wrapper img{
        width: 20px;
        height: 38x;
        padding: 0 150px;
        padding-top: 30px;
      }

    .testimonial-container .icon-wrapper {
      margin: 0 20px;
    }

    .testimonial-container #background-img1 {
      right: 7%;
      top: 9%;
    }

    .testimonial-container #background-img2 {
      left: -13%;
      bottom: -5%;
    }
  
}

@media screen and (max-width: 768px ) {
  .testimonial-container #background-img1, .testimonial-container #background-img2, .testimonial-container .icon-wrapper img, .indicator, .icon-wrapper{
      display: none;
  }
  .testimonial-container{
      margin-bottom: 0;
      padding-bottom: 0;
  }

  .testimonial-container .section-heading{
      margin-top: 0;
      margin-bottom: 0;
  }

  .testimonial-container .each-slide .testimonial-card-container{
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  }
  .testimonial-container .testimonial-card-container .testimonial-image{
      top: 0px;
  }
}