.admin-topbar {
    padding: 20px 30px;
    background-color: var(--primary-color);
    border-left: 4px solid var(--white-color);
}

.admin-topbar .admin-topbar-greeting {
    color: var(--gray-color);
    font-family: var(--font-heading);
    font-size: 20px;
    font-weight: 500;
    margin: 0;
}

.admin-topbar .admin-account{
    z-index: 1;
}

.admin-topbar .admin-account .admin-account-dropdown {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    cursor: pointer;
}

.admin-topbar .admin-account .admin-account-dropdown span {
    color: var(--black-color);
    font-size: 16px;
    font-family: var(--font-primary);
    font-weight: 500;
    margin-left: 4px;
}

.admin-account .admin-dropdown-list {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    gap: 8px;
    color: var(--gray-color);
    font-size: 16px;
    font-family: var(--font-primary);
    font-weight: 500;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
}

.admin-account .admin-dropdown-list:hover {
    background-color: rgb(107, 116, 131, 0.1);
    color: var(--black-color);
}

.dropdown-admin {
  position: relative;
}

.menu-admin {
  position: absolute;
  list-style-type: none;
  margin: 10px 0;
  padding: 10px;
  width: 176px;
  background: var(--white-color);
  box-shadow: 0px 6px 25px 0px rgba(92, 101, 116, 0.15);
  border-radius: 8px;
}


@media screen and (max-width: 768px) {
    .admin-topbar .admin-topbar-greeting {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 16px;
    }
}

/* Tablets */
@media (min-width: 768px) and (max-width: 992px) {
    .admin-topbar .admin-topbar-greeting {
        font-size: 18px;
    }
}