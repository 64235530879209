.category-button {
    position: relative;
    display: inline-block;
  }
  
  .category-button button {
    display: flex;
    padding: 10px 15px 10px 25px;
    align-items: center;
    gap: 5px;
    border-radius: 8px;
    border: 1px solid rgba(107, 116, 131, 0.50);
    color: var(--gray, #6B7483);
    font-size: 16px;
    font-family: var(--font-primary, Inter);
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    background-color: var(--white, #FFFFFF);
    cursor: pointer;
    transition: all 0.3s ease-in-out;
  }
  .category-button button:hover {
    background-color: var(--gray-light, #F4F4F4);
  }
  .category-button .down-arrow {
    width: 18px;
    height: 18px;
  }
  
  .category-button ul {
    position: absolute;
    top: 70%;
    left: 0;
    width: 200px;
    padding: 8px;
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    list-style-type: none;
    border-radius: 6px;
  }
  
  .category-button ul li {
    padding: 4px 0;
    cursor: pointer;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    gap: 8px;
    color: var(--gray-color);
    font-size: 16px;
    font-family: var(--font-primary);
    font-weight: 500;
    cursor: pointer;
    border-radius: 5px;
    transition: all 0.3s ease-in-out;
  }
  
  .category-button ul li:hover {
    background-color: #f0f0f0;
  }
  