/* Fonts from Google */
@import url("https://fonts.googleapis.com/css2?family=Kristi:wght@400;500;600;700;800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800&display=swap");

@import url("https://fonts.googleapis.com/css2?family=David+Libre:wght@400;500;600;700;800&display=swap");

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', 'Inter', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    overflow-x: hidden;
}

code {
font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --font-kristi: 'Kristi';
  --font-heading: David Libre;
  --font-primary: Inter;

  --primary-color: rgb(107, 116, 131, 0.05);
  --secondary-color: #FF9400;
  --hover-color: #EA6D27;
  --black-color: #101A24;
  --gray-color: #6B7483;
  --white-color: #ffffff;
  --yellow-color: #FFBB00;
}

.section-heading {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.subtitle {
  font-family: var(--font-kristi);
  font-size: 56px;
  font-weight: 400;
  text-align: center;
  line-height: 65px;
  color: var(--secondary-color);
  margin-bottom: -50px;
}

.title {
  font-family: var(--font-heading);
  font-size: 64px;
  font-weight: 700;
  line-height: 65px;
  letter-spacing: 0em;
  text-align: center;
  color: var(--black-color);
}

.section-heading p {
  color: var(--gray-color);
  font-size: 16px;
  margin-top: -20px;
  width: 30%;
}

.container {
  padding: 0 180px;
}

.our-location-section {
  padding-top: 90px;
  padding-bottom: 120px;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0 30px;
  }
  .subtitle {
    font-size: 36px;
    margin-bottom: -42px;
  }
  .title {
    font-size: 40px;
    line-height: 50px;
  }
  .section-heading p {
    font-size: 14px;
    width: 80%;
  }
  .our-location-section {
    padding-top: 60px;
    padding-bottom: 90px;
  }
}

@media (min-width: 768px) and (max-width: 992px) {
  .container {
    padding: 0 90px;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .container {
    padding: 0 130px;
  }
}