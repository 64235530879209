.menu-categories-section {
    padding-top: 196px;
    padding-bottom: 156px;
}

.menu-categories-section .menu-categories-list {
    padding-top: 32px;
}

.menu-categories-list .menu-categories-btn, .menu-categories-list .menu-selectedCategories-btn {
    white-space: nowrap;
    cursor: pointer;
    transition: all 0.3s ease-in;
}

.menu-categories-list .menu-categories-btn p, .menu-categories-list .menu-selectedCategories-btn p {
    font-family: var(--font-heading);
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: 0.5px;
    color: var(--black-color);
    transition: all 0.3s ease-in;
    margin-top: 0;
    margin-bottom: 4px;
}

.menu-categories-list .menu-categories-btn p {
    opacity: 0.6;
}

.menu-categories-list .menu-categories-btn:hover p {
    opacity: 1;
}

.menu-categories-list .menu-selectedCategories-btn {
    border-bottom: 4px solid var(--secondary-color);
    border-radius: 4px;
}

.menu-categories-section hr {
    width: 100%;
    color: var(--gray-color);
    opacity: 0.2;
    margin-top: -4px;
    border-radius: 5px;
    z-index: -1;
}

.menu-categories-section .backgroung-img1 img {
    height: 258px;
    width: 180px;
}

.menu-categories-section .backgroung-img1 {
    position: absolute;
    margin-top: 57px;
    right: 17%;
    z-index: -1;
}

.menu-categories-section .backgroung-img2 img {
    height: 142px;
    width: 382px;
}

.menu-categories-section .backgroung-img2 {
    position: absolute;
    margin-top: 390px;
    left: 5%;
    z-index: -1;
    transform: rotate(12.2deg);
}

/* MenuList.jsx */
.menu-categories-item-list {
    padding-top: 48px;
}

.menu-categories-item-list .menu-item-card {
    background: var(--white-color);
    border: 1px solid rgba(107, 116, 131, 0.4);
    border-radius: 15px;
    transition: all 0.3s ease-in-out;
}

.menu-categories-item-list .menu-item-card:hover {
    border: none;
    border: 1px solid var(--white-color);
    box-shadow: 0px 6px 25px rgba(92, 101, 116, 0.15);
}

.menu-categories-item-list .menu-item-img img {
    width: 86px;
    height: 86px;
    border-radius: 50%;
}

.menu-categories-item-list .menu-item-text {
    width: 100%;
    text-align: center;
    padding-top: 20px;
}

.menu-categories-item-list .menu-item-text h2 {
    font-family: var(--font-primary);
    font-weight: 500;
    font-size: 18px;
    line-height: 25px;
    color: var(--black-color);
    margin: 0;
}

.menu-categories-item-list .menu-item-text p {
    font-family: var(--font-primary);
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: var(--gray-color);
    margin-top: 10px;
    margin-bottom: 0;
    opacity: 0.8;
}

.menu-categories-item-list .menu-item-price {
    background: var(--secondary-color);
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 6px 20px;
    margin-top: 25px;
    transition: all 0.3s ease-in-out;
}

/* .menu-categories-item-list .menu-item-price:hover {
    background: var(--hover-color);
} */

.menu-categories-item-list .menu-item-price p {
    font-family: var(--font-primary);
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: var(--white-color);
    margin: 0;
}

.menu-categories-item-list .view-all-button {
    background-color: transparent;
    border: 1px solid var(--secondary-color);
    color: var(--secondary-color);
    border-radius: 10px;
    font-size: 16px;
    letter-spacing: 1px;
    padding: 10px 30px;
    margin: 20px auto;
    cursor: pointer;
    display: block;
    transition: all 0.3s ease-in-out;
}

.menu-categories-item-list .view-all-button:hover {
    background-color: var(--hover-color);
    border: 1px solid var(--hover-color);
    color: var(--white-color);
}


/* Media Query for the Responsiveness */

/* Mobile */
@media screen and (max-width: 768px) {
    .menu-categories-section {
        padding-top: 120px;
        padding-bottom: 120px;
    }
    /* .menu-categories-section .backgroung-img1 {
        margin-top: 42px;
        right: -26%;
    } */
    .menu-categories-section .backgroung-img1, .menu-categories-section .backgroung-img2 {
        display: none;
    }
    .menu-categories-section .menu-categories-list {
        padding-top: 32px;
    }
}

/* Tablets */
@media (min-width: 768px) and (max-width: 992px) {
    .menu-categories-section {
        padding-top: 120px;
        padding-bottom: 156px;
    }
    .menu-categories-section .backgroung-img1 {
        margin-top: 70px;
        right: 3%;
    }
    .menu-categories-section .backgroung-img2 {
        margin-top: 410px;
        left: 2%;
    }
}

/* Small Laptop & Desktop */
@media (min-width: 992px) and (max-width: 1200px) {
    .menu-categories-item-list .css-1gr6ves {
        width: 30% !important;
    }
    .menu-categories-section .backgroung-img1 {
        margin-top: 70px;
        right: 12%;
    }
    .menu-categories-section .backgroung-img2 {
        margin-top: 410px;
        left: 3%;
    }
}