.loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: var(--white-color);

    /* height: 100%; */
    z-index: 9999;
}
  
.loader {
    width: 200px; /* Adjust the width and height according to your GIF size */
    height: 200px;
    z-index: -1;
}

@media screen and (max-width: 768px) {
    .loader {
        width: 150px;
        height: 150px;
    }
}