.google-map {
    width: 92vw;
    height: 460px;
    border-radius: 10px;
    /* background-color: var(--primary-color); */
}

/* .google-map iframe {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
} */

@media screen and (max-width: 768px) {
    .google-map {
        height: 320px;
    }
}