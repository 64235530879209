
.daily-deals-card{
    width: 364px;
    height: 303px;
    position: relative;
    border-radius: 10px;
    display: flex;
    background-color: var(--white-color);
}

.daily-deals-card .daily-deals-card-image{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    width: 100%;
    height: 212px;
}

.daily-deals-card .daily-deals-card-image img{
    width: 100%;
    height: 100%;
    border-radius: 10px;
    object-fit: cover;
    overflow: hidden;
}

.daily-deals-card .daily-deals-card-content{
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.daily-deals-card .daily-deals-card-content .daily-deals-card-title{
    margin-top: 24px;
    font-family: var(--font-heading);
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 25px;
    text-align: center;
    color: var(--black-color);
}

.daily-deals-card .daily-deals-card-content .daily-deals-card-description{
    margin-top: 6px;
    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;    
    color: var(--gray-color);
}

@media screen and (max-width: 768px) {
    .daily-deals-card{
        width: 300px;
        height: 303px;
        margin-top: 0;
        margin-bottom: -30px;
    }
    .daily-deals-card .daily-deals-card-image{
        width: 300px;
        height: 160px;
    }
    .daily-deals-card .daily-deals-card-content{
        width: 300px;
        height: 143px;
    }
    .daily-deals-card .daily-deals-card-content .daily-deals-card-title{
        font-size: 24px;
    }
    .daily-deals-card .daily-deals-card-content .daily-deals-card-description{
        font-size: 12px;
    }
}

@media (min-width: 768px) and (max-width: 1280px){
    .daily-deals-card{
        width: 300px;
        height: 303px;
        margin-top: 0;
        margin-bottom: -30px;
    }
    .daily-deals-card .daily-deals-card-image{
        width: 300px;
        height: 160px;
    }
    .daily-deals-card .daily-deals-card-content{
        width: 300px;
        height: 143px;
    }
    .daily-deals-card .daily-deals-card-content .daily-deals-card-title{
        font-size: 24px;
    }
    .daily-deals-card .daily-deals-card-content .daily-deals-card-description{
        font-size: 12px;
    }
}