.hero-section {
    padding-top: 220px;
    padding-bottom: 160px;
}

.hero-section .hero-flex-box {
    display: flex;
    justify-content: flex-start;
    /* align-items: flex-start; */
    width: 100%;
}

.hero-section .hero-content .hero-text h1 {
    font-family: var(--font-heading);
    font-style: normal;
    font-weight: 700;
    font-size: 70px;
    line-height: 72px;
    /* width: 510px; */
    color: var(--black-color);
}

.hero-section .hero-content .hero-text p {
    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    width: 376px;
    color: var(--gray-color);
}

.hero-section .hero-content .hero-text .hero-cta-box {
    padding-top: 40px;
}

.hero-section .hero-content .hero-text a {
    text-decoration: none;
}

.hero-section .hero-content .hero-text .hero-cta-btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 12px 30px;
    border-radius: 16px 5px 16px 5px;

    /* Text */
    font-family: var(--font-heading);
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    text-align: center;
    color: var(--white-color);
    transition: all 0.3s ease-in-out;
}

.hero-section .hero-content .hero-text .cta-menu {
    background-color: var(--black-color);
}

.hero-section .hero-content .hero-text .cta-menu:hover {
    opacity: 0.9;
}

.hero-section .hero-content .hero-text .cta-cantact {
    background-color: var(--secondary-color);
}

.hero-section .hero-content .hero-text .cta-cantact:hover {
    background-color: var(--hover-color);
}

.hero-section .hero-content .hero-text .hero-socialMedia {
    padding-top: 64px;
}

.hero-section .hero-content .hero-text .hero-socialMedia img {
    height: 40px;
    width: 40px;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

.hero-section .hero-content .hero-text .hero-socialMedia img:hover {
    opacity: 0.7;
}

.hero-section .hero-content .hero-text .hero-socialMedia .hero-line {
    width: 10vw;
    height: 1.5px;
    background-color: var(--gray-color);
    opacity: 0.5;
    border-radius: 10px;
}

.hero-section .hero-img .hero-mainImg .img {
    /* width: 516px; */
    width: 33.6vw;
    height: 712px;
    object-fit: cover;
    border-radius: 90px 45px 90px 45px;
}

.hero-section .hero-img .hero-dishImg {
    position: absolute;
    margin-top: 335px;
    /* margin-right: 24%; */
    right: 35.5%;
    z-index: 1;
}

.hero-section .hero-img .hero-dishImg .img {
    /* width: 364px; */
    /* height: 364px; */
    width: 23.7vw;
    height: auto;
}

/* Background images */
.hero-section .backgroung-img1 {
    position: absolute;
    margin-top: -1432px;
    margin-left: -51%;
    z-index: -1;
}

.hero-section .backgroung-img1 img {
    width: 1204px;
    height: 1204px;
}

/*  */
.hero-section .backgroung-img2 {
    position: absolute;
    margin-top: -685px;
    /* margin-left: 31.5%; */
    z-index: -1;
    right: 27.5%;
}

.hero-section .backgroung-img2 .img {
    width: 448px;
    height: 192px;
}

/*  */
.hero-section .backgroung-img3 {
    position: absolute;
    margin-top: -640px;
    /* margin-left: 63.5%; */
    right: 4%;
    z-index: -1;
}

.hero-section .backgroung-img3 .img {
    width: 316px;
    height: 300px;
}

/*  */
.hero-section .backgroung-img4 {
    position: absolute;
    margin-top: 316px;
    /* margin-left: 70.5%; */
    right: 1.5%;
    z-index: -1;
}

.hero-section .backgroung-img4 .img {
    width: 252px;
    height: 342px;
}

/* Media Query for the Responsiveness */

@media screen and (min-width: 768px) {
    .hero-section .hero-content {
        width: 50%;
        display: flex;
        justify-content: flex-start;
    }
    .hero-section .hero-img {
        width: 50%;
        display: flex;
        justify-content: flex-end;
    }
}

/* Tablets */
@media (min-width: 768px) and (max-width: 900px) {
    .hero-section {
        padding-top: 54px;
        padding-bottom: 120px;
      }
    .hero-section .hero-content .hero-text h1 {
        font-size: 50px;
    }
    .hero-section .hero-content .hero-text p {
        font-size: 14px;
        margin-top: -16px;
    }
    .hero-section .backgroung-img1, .hero-section .backgroung-img3 {
        display: none;
    }
    .hero-section .backgroung-img2 {
        left: 2%;
        top: 73%;
    }
    .hero-section .backgroung-img2 .img {
        width: 380px;
        height: auto;
    }
    .hero-section .backgroung-img4 {
        right: 1%;
        top: 23%;
    }
    .hero-section .backgroung-img4 .img {
        width: 180px;
        height: auto;
    }
    .hero-section .hero-img .hero-mainImg .img {
        height: 560px;
        width: 200%;
    }
    .hero-section .hero-img .hero-dishImg {
        /* top: 27%; */
        margin-top: 445px;
        right: 23%;
    }
}

@media (min-width: 900px) and (max-width: 992px) {
    .hero-section .hero-content .hero-text h1 {
        font-size: 50px;
    }
    .hero-section .hero-content .hero-text p {
        font-size: 14px;
        margin-top: -16px;
    }
    .hero-section .backgroung-img1 {
        margin-top: -1274px;
        margin-left: -62%;
    }
    .hero-section .backgroung-img1 img {
        width: 992px;
        height: auto;
    }
    .hero-section .backgroung-img2 {
        margin-top: -535px;
        right: 24%;
    }
    .hero-section .backgroung-img2 .img {
        width: 330px;
        height: auto;
    }
    .hero-section .backgroung-img3 {
        margin-top: -514px;
        right: 2%;
    }
    .hero-section .backgroung-img3 .img {
        width: 220px;
        height: auto;
    }
    .hero-section .backgroung-img4 {
        margin-top: 280px;
        right: 1%;
    }
    .hero-section .backgroung-img4 .img {
        width: 180px;
        height: auto;
    }
    .hero-section .hero-img .hero-mainImg .img {
        height: 560px;
        width: 37vw;
    }
    .hero-section .hero-img .hero-dishImg {
        /* top: 27%; */
        margin-top: 237px;
        right: 34%;
    }
}

/* Small Laptop & Desktop */
@media (min-width: 992px) and (max-width: 1200px) {
    .hero-section .backgroung-img1 img {
        width: 992px;
        height: auto;
    }
    .hero-section .backgroung-img2 {
        margin-top: -624px;
        right: 23%;
    }
    .hero-section .backgroung-img2 .img {
        width: 390px;
        height: auto;
    }
    .hero-section .backgroung-img3 {
        margin-top: -584px;
        right: 3%;
    }
    .hero-section .backgroung-img3 .img {
        width: 256px;
        height: auto;
    }
    .hero-section .backgroung-img4 .img {
        width: 212px;
        height: auto;
    }
    .hero-section .hero-img .hero-mainImg .img {
        height: 640px;
    }
    .hero-section .hero-img .hero-dishImg {
        /* top: 33%; */
        margin-top: 321px;
    }
    .hero-section .hero-content .hero-text h1 {
        font-size: 60px;
    }
    .hero-section .hero-content .hero-text p {
        font-size: 15px;
    }
    .hero-section .hero-content .hero-text .hero-cta-btn {
        padding: 11px 30px;
    }
    .hero-section .hero-content .hero-text .hero-cta-box {
        padding-top: 30px;
    }
}

/* Mobile devices */
@media screen and (max-width: 768px) {
    .hero-section {
        padding-top: 132px;
        padding-bottom: 90px;
    }
    .hero-section .backgroung-img1, .hero-section .backgroung-img2 {
        display: none;
    }
    .hero-section .hero-img {
        /* padding-bottom: 180px; */
        padding-bottom: 36vw;
    }
    .hero-section .hero-img .hero-mainImg .img {
        width: 100%;
        height: 523px;
        border-radius: 50px 15px 50px 15px;
    }
    .hero-section .hero-img .hero-dishImg {
        margin-top: 416px;
        left: 42%;
        /* right: -12%; */
    }
    .hero-section .hero-img .hero-dishImg .img {
        width: 45vw;
        height: auto;
    }
    .hero-section .hero-content .hero-text h1 {
        font-size: 46px;
        line-height: 60px;
        width: 100%;
    }
    .hero-section .hero-content .hero-text p {
        font-size: 14px;
        width: 100%;
        margin-top: -10px;
    }
    .hero-section .hero-content .hero-text .hero-cta-box {
        padding-top: 20px;
    }
    .hero-section .hero-content .hero-text .hero-cta-btn {
        padding: 10px 26px;
        border-radius: 16px 5px 16px 5px;
        font-size: 18px;
    }
    .hero-section .hero-content .hero-text .hero-socialMedia {
        padding-top: 40px;
    }
    .hero-section .hero-content .hero-text .hero-socialMedia .hero-line {
        width: 40%;
    }
    /*  */
    .hero-section .backgroung-img3 {
        margin-top: 538px;
        top: 18%;
        left: -7%;
    }
    .hero-section .backgroung-img3 .img {
        width: 40vw;
        height: auto;
    }
    /*  */
    .hero-section .backgroung-img4 {
        margin-top: -50px;
        margin-left: 47%;
        right: 1%;
        top: 18%;
    }
    .hero-section .backgroung-img4 .img {
        width: 44vw;
        height: auto;
    }
}
