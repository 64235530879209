.about-us-section{
  padding-top: 196px;
  padding-bottom: 156px;
}

.about-us-section .aboutus-img-grid .aboutus-img .img {
  width: 100%;
  height: auto;
  border-radius: 75px 30px 75px 30px;
}

.aboutus-content-grid {
  padding-left: 98px;
  padding-right: 28px;
}

.aboutus-content-grid .aboutus-text {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 32px;
  letter-spacing: 0.01em;
  color: var(--black-color);
  margin: 0;
}

.aboutus-content-grid .aboutus-cta-box a {
  text-decoration: none;
}

.aboutus-content-grid .aboutus-cta-box .aboutus-cta-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 12px 30px;
  border-radius: 16px 5px 16px 5px;

  /* Text */
  font-family: var(--font-heading);
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  color: var(--white-color);
  transition: all 0.3s ease-in-out;
}

.aboutus-content-grid .aboutus-cta-box .cta-menu {
  background-color: var(--black-color);
}

.aboutus-content-grid .aboutus-cta-box .cta-menu:hover {
  opacity: 0.9;
}

.aboutus-content-grid .aboutus-cta-box .cta-cantact {
  background-color: var(--secondary-color);
}

.aboutus-content-grid .aboutus-cta-box .cta-cantact:hover {
  background-color: var(--hover-color);
}

.about-us-section .backgroung-img1 img {
  height: 258px;
  width: 180px;
}

.about-us-section .backgroung-img1 {
  position: absolute;
  margin-top: -176px;
  right: 17%;
  z-index: -1;
}

.about-us-section .backgroung-img2 img {
  height: 280px;
  width: 426px;
  transform: rotate(110deg);
}

.about-us-section .backgroung-img2 {
  position: absolute;
  margin-top: 48px;
  left: 26%;
  z-index: -1;
}

.about-us-section .backgroung-img3 img {
  height: auto;
  width: 224px;
}

.about-us-section .backgroung-img3 {
  position: absolute;
  margin-top: -100px;
  left: 5%;
  z-index: -1;
}

/* From Us */
.from-us-section {
  padding-top: 156px;
}

.from-us-section .section-heading .title {
  margin-top: 45px;
}

/* Overlay */
.fromus-content {
  position: relative;
  width: 100%;
  height: fit-content;
  /* height: 300px; */
  /* max-width: 500px;   */
  margin: auto;
  overflow: hidden;
}

.fromus-content .fromus-content-overlay {
  background: rgba(0,0,0,0.7);
  position: absolute;
  height: 99%;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  -webkit-transition: all 0.4s ease-in-out 0s;
  -moz-transition: all 0.4s ease-in-out 0s;
  transition: all 0.4s ease-in-out 0s;
  border-radius: 14px;
  z-index: 1;
}

.fromus-content:hover .fromus-content-overlay{
  opacity: 1;
}

.fromus-content-image{
  width: 100%;
  z-index: -1;
}

.fromus-content-details {
  position: absolute;
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  width: 100%;
  top: 50%;
  left: 50%;
  opacity: 0;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transition: all 0.3s ease-in-out 0s;
  -moz-transition: all 0.3s ease-in-out 0s;
  transition: all 0.3s ease-in-out 0s;
}

.fromus-content:hover .fromus-content-details{
  top: 50%;
  left: 50%;
  opacity: 1;
}

.fromus-content-details {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
  z-index: 1;
}

.fromus-content-details h1 {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 800;
  font-size: 56px;
  line-height: 65px;
  text-align: center;
  letter-spacing: 0.06em;
  color: var(--white-color);
  opacity: 0.8;
  margin: 0;
}

.fromus-content-details .fromus-content-btn {
  padding: 12px 30px;
  border-radius: 16px 5px 16px 5px;
  width: fit-content;

  /* Text */
  font-family: var(--font-heading);
  font-weight: 700;
  font-size: 20px;
  line-height: 29px;
  text-align: center;
  color: var(--secondary-color);
  background-color: transparent;
  border: 1px solid var(--secondary-color);
  transition: all 0.3s ease-in;
}

.fromus-content-details .fromus-content-btn:hover {
  background-color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
  color: var(--white-color);
}

.fadeIn-top{
  top: 20%;
}

/* Mobile Responsiveness */
/* Tablets */
@media (min-width: 768px) and (max-width: 992px) {
  .about-us-section{
    padding-top: 120px;
    padding-bottom: 156px;
  }
  .about-us-section .backgroung-img1 {
    margin-top: -180px;
    right: 9%;
  }
  .about-us-section .backgroung-img2 {
    display: none;
  }
  .about-us-section .backgroung-img3 {
    margin-top: -400px;
    left: -3%;
  }
  .about-us-section .aboutus-img-grid .aboutus-img .img {
    border-radius: 90px 36px 90px 36px;
  }
  .aboutus-content-grid {
    padding: 60px 0 0 0;
  }
  .fromus-content-details h1 {
    font-size: 70px;
  }
}

/* Small Laptop & Desktop */
@media (min-width: 992px) and (max-width: 1200px) {
  .about-us-section .backgroung-img2 {
    margin-top: 48px;
    left: 22%;
  }
  .fromus-content-details h1 {
    font-size: 48px;
    line-height: 36px;
  }
}

/* Mobile devices */
@media screen and (max-width: 768px) {
  .about-us-section{
    padding-top: 120px;
    padding-bottom: 120px;
  }
  /* .about-us-section .backgroung-img1 {
    margin-top: -140px;
    right: -23%;
  } */
  .about-us-section .backgroung-img1, .about-us-section .backgroung-img2 {
    display: none;
  }
  .about-us-section .backgroung-img3 {
    margin-top: -574px;
    left: -16%;
  }
  .about-us-section .aboutus-img-grid .aboutus-img .img {
    border-radius: 60px 15px 60px 15px;
  }
  .aboutus-content-grid {
    padding: 46px 0 0 0;
  }
  .aboutus-content-grid .aboutus-text {
    font-size: 16px;
    line-height: 30px;
  }
  .aboutus-content-grid .aboutus-cta-box .aboutus-cta-btn {
    font-size: 18px;
    padding: 12px 26px;
  }

  .from-us-section {
    padding-top: 90px;
  }
  .from-us-section .section-heading .title {
    margin-top: 16px;
  }
  .fromus-content-details {
    top: 50%;
    left: 50%;
    opacity: 1;
  }
  .fromus-content-details h1 {
    color: var(--white-color);
    font-weight: 800;
    font-size: 36px;
    line-height: 24px;
  }
  .fromus-content-details .fromus-content-btn {
    padding: 12px 24px;
    border-radius: 16px 5px 16px 5px;
    font-size: 18px;
    line-height: 20px;
    background-color: var(--secondary-color);
    border: 1px solid var(--secondary-color);
    color: var(--white-color);
  }
  .fromus-content .fromus-content-overlay {
    background: rgba(0,0,0,0.3);
    height: 98%;
    width: 100%;
    border-radius: 10px;
    opacity: 1;
  }
}

