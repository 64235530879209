.special-menu-section {
    padding-top: 100px;
    padding-bottom: 155px;
}

.special-menu-card {
    width: 44%;
    padding: 0 1rem;
}

.special-menu-card .special-menu-head .special-menu-title h4, .special-menu-card .special-menu-head .special-menu-price h3 {
    margin-top: 0;
    margin-bottom: 0;
}

.special-menu-card .special-menu-head .special-menu-title h4 {
    font-family: var(--font-heading);
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    color: var(--black-color);
}

.special-menu-card .special-menu-head .special-menu-price h3 {
    font-family: var(--font-primary);
    font-weight: 700;
    font-size: 20px;
    line-height: 29px;
    color: var(--black-color);
}

.special-menu-card .special-menu-desc p {
    font-family: var(--font-primary);
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--gray-color);
    margin-top: 10px;
}

.special-menu-card .special-menu-head .special-menu-breakline {
    border: 2px dashed var(--gray-color); 
    border-style: none none dashed;
    opacity: 0.7;
}

.special-menu-section .special-menu-btn {
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    color: var(--secondary-color);
    border-bottom: 3px solid var(--secondary-color);
    border-radius: 2px;
    transition: all 0.3s ease-in-out;
    cursor: pointer;
    padding-top: 30px;
}

.special-menu-section .special-menu-btn p {
    font-family: var(--font-primary);
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    color: var(--secondary-color);
    text-decoration: none;
    margin: 0;
    transition: all 0.3s ease-in-out;
}

.special-menu-section .special-menu-btn:hover {
    color: var(--hover-color);
    border-bottom: 3px solid var(--hover-color);
    gap: 15px;
}

.special-menu-section .special-menu-btn:hover p {
    color: var(--hover-color);
}

/* Background images */
.special-menu-section .backgroung-img1 {
    position: absolute;
    margin-top: 80px;
    margin-left: 55%;
    z-index: -1;
}

.special-menu-section .backgroung-img1 img {
    width: 180px;
    height: auto;
}

/*  */
.special-menu-section .backgroung-img2 {
    position: absolute;
    margin-top: 40%;
    margin-left: -2%;
    z-index: -1;
}

.special-menu-section .backgroung-img2 img {
    width: 224px;
    height: auto;
}

/* Media Query for the Responsiveness */

/* Mobile */
@media screen and (max-width: 768px) {
    .special-menu-section {
        padding-top: 60px;
        padding-bottom: 120px;
    }
    .special-menu-card {
        width: 100%;
        padding: 0;
    }
    .special-menu-section .backgroung-img2 {
        display: none;
    }
    .special-menu-section .backgroung-img1 {
        margin-top: 21px;
        margin-left: 63%;
        display: none;
    }
    .special-menu-section .special-menu-btn p {
        font-size: 18px;
    }
    .special-menu-section .special-menu-btn {
        padding-top: 10px;
    }
}

/* Tablets */
@media (min-width: 768px) and (max-width: 992px) {
    .special-menu-section {
        padding-top: 75px;
        padding-bottom: 120px;
    }
    .special-menu-card {
        width: 100%;
        padding: 0;
    }
    .special-menu-section .backgroung-img1 {
        margin-top: 73px;
        margin-left: 63%;
    }
    .special-menu-section .backgroung-img2 {
        margin-top: 114%;
        margin-left: -2%;
    }
}

/* Small Laptop & Desktop */
@media (min-width: 992px) and (max-width: 1200px) {
    .special-menu-section {
        padding-top: 90px;
        padding-bottom: 135px;
    }
    .special-menu-card {
        width: 46%;
        padding: 0;
    }
    .special-menu-section .backgroung-img1 {
        margin-top: 73px;
        margin-left: 58%;
    }
    .special-menu-section .backgroung-img2 {
        margin-top: 57%;
        margin-left: -2%;
    }
}