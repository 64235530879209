
.liquor-card {
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 16px;
    height: 591px;
    width: 463px;
    position: relative;
    border-radius: 16px;
  }

  @keyframes exit-entry {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
  .card-exit-entry {
    animation: exit-entry 0.2s ease-in-out forwards;
  }
  
  .liquor-card .liquor-image-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 591px;
    width: 463px;
  }

  .liquor-card .liquor-image-container .liquor-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    overflow: hidden;
    border-radius: 16px;
  }

  
  .liquor-card .liquor-image-container .price-circle {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 68px;
    height: 68px;
    background-color: var(--secondary-color);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .liquor-card .liquor-image-container .price-circle .price {
    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 25px;
    text-align: center;
    color: var(--white-color);
  }
  
  .liquor-card .liquor-details-container {
    box-sizing: border-box;
    position: absolute;
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 420px;
    bottom: 20px;
    left: 20px;
    background: rgba(16, 26, 36, 0.5);
    border: 1px solid rgba(255, 255, 255, 0.15);
    backdrop-filter: blur(2px);
    border-radius: 10px;
    padding: 30px;
  }
  
  .liquor-card .liquor-details-container .liquor-name {
    font-family: var(--font-heading);
    font-style: normal;
    font-weight: 700;
    font-size: 28px;
    line-height: 25px;
    color: var(--white-color);
    margin-bottom: 15px;
  }
  
  .liquor-card .liquor-details-container .liquor-description {
    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: rgba(255, 255, 255, 0.6);
  }

  @media screen and (max-width: 768px) {
    .liquor-card {
      height: 400px;
      width: 300px;
    }
    .liquor-card .liquor-image-container {
      height: 400px;
      width: 300px;
    }
    .liquor-card .liquor-details-container {
      width: 260px;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
  .liquor-card {
    height: 400px;
    width: 300px;
  }
  .liquor-card .liquor-image-container {
    height: 400px;
    width: 300px;
  }
  .liquor-card .liquor-details-container {
    width: 260px;
  }
}
  