/* Style for LiquorSlider component */
.liquor-slider {
  display: flex;
  align-items: flex-start;
  position: relative;
  background-color: rgba(16, 26, 36, 0.05);
  height: 903px;
  width: 100%;
  box-sizing: border-box;
}

.liquor-slider .category-container-loading{
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  top: 370px;
  right: 180px;
  gap: 20px;
}

.liquor-slider .category-container {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  top: 298px;
  right: 180px;
}

.liquor-slider .category-container .category {
  cursor: pointer;
  font-family: var(--font-heading);
  font-style: normal;
  font-weight: 700;
  font-size: 64px;
  line-height: 65px;
  color: rgba(107, 116, 131, 0.7);
}

.liquor-slider .category-container .category:hover {
  color: #ccc;
}

.liquor-slider .category-container .category.active {
  color: #101A24;
}

.liquor-slider .category-container .line{
  width: 435px;
  height: 0px;
  border: 1px solid rgba(107, 116, 131, 0.3);
  margin-top: 25px;
  margin-bottom: 25px;
}

.liquor-slider .category-container .line-skeleton{
  width: 100%;
  border: 1px solid rgba(107, 116, 131, 0.3);
  margin: 10px 0;
}

.liquor-slider .liquor-card-container {
  display: flex;
  flex-wrap: wrap;
  flex: 1;
  position: absolute;
  left: 180px;
  top: 156px;
  margin-bottom: 156px;
}

.liquor-slider .liquor-card-container .background-img {
  display: flex;
  position: absolute;
  bottom: -115px;
  left: -119px;
  z-index: -1;
}

.liquor-slider .liquor-card-container .background-img img {
  width: 224.2px;
  height: 212.7px;
  opacity: 0.1;
}

.liquor-slider .arrow{
display: flex;
position: absolute;
flex-direction: column;
justify-content:flex-end;
width: 48px;
height: 340px;
left: 45%;
margin-top: 18%;
}

.liquor-slider .arrow .previous-arrow{
height: 48px;
width: 48px;
border: 2px solid rgb(133, 133, 133);
border-radius: 50%;
margin-bottom: 239px;
cursor: pointer;
text-align: center;
}

.liquor-slider .arrow .previous-arrow:hover{
opacity: 0.5;
}

.liquor-slider .arrow .previous-arrow .prev-icon{
  font-size: 48px;
  line-height: 48px;
  text-align: center;
  color: rgb(133, 133, 133);
}

.liquor-slider .arrow .next-arrow{
height: 48px;
width: 48px;
border: 2px solid rgb(133, 133, 133);
border-radius: 50%;
bottom: 0;
cursor: pointer;
}

.liquor-slider .arrow .next-arrow:hover{
opacity: 0.5;
}

.liquor-slider .arrow .next-arrow .next-icon{
  font-size: 48px;
  line-height: 48px;
  text-align: center;
  color: rgb(133, 133, 133);
  transform: rotate(180deg);
}

@media screen and (max-width: 768px) {
  .liquor-slider .liquor-card-container .background-img, .liquor-slider .arrow{
      display: none;
  }
  .liquor-slider {
    flex-direction: column;
    align-items: center;
    background-color: rgba(16, 26, 36, 0.05);
    height: 860px;
  }

  .liquor-slider .liquor-card-container {
    position: relative;
    left: 0;
    top: 15%;
    margin-bottom: 0;
  }
  .liquor-slider .category-container-loading{
    position: relative;
    top: 0;
    right: 0;
    align-items: center;
    margin-bottom: 20px;
  }
  .liquor-slider .category-container {
    position: relative;
    top: -7%;
    right: 0;
  }
  .liquor-slider .category-container .line{
    width: 300px;
  }
  .liquor-slider .category-container .category {
    font-size: 32px;
    line-height: 33px;
  }

}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .liquor-slider .liquor-card-container .background-img{
      display: none;
  }
  .liquor-slider {
    /* flex-direction: column; */
    align-items: center;
    background-color: rgba(16, 26, 36, 0.05);
    height: 653px;
  }

  .liquor-slider .arrow{
    padding-left: 30px;
    padding-bottom: 220px;
  }

  .liquor-slider .arrow .previous-arrow{
    margin-bottom: 140px;
  }

  .liquor-slider .liquor-card-container {
    top: 5%;
    left: 10%;
    position: relative;
    margin-bottom: 0;
  }
  .liquor-slider .category-container {
    position: relative;
    top: 1%;
    right: 12%;
  }
  .liquor-slider .category-container .line{
    width: 200px;
  }
  .liquor-slider .category-container .category {
    font-size: 32px;
    line-height: 33px;
  }
}

@media screen and (min-width: 992px) and (max-width: 1280px) {
  .liquor-slider .liquor-card-container .background-img{
      display: none;
  }
  .liquor-slider {
    align-items: center;
    background-color: rgba(16, 26, 36, 0.05);
    height: 800px;
  }

  .liquor-slider .liquor-card-container {
    top: 10%;
    left: 10%;
    position: relative;
    margin-bottom: 0;
  }

  .liquor-slider .arrow{
    padding-left: 30px;
    padding-bottom: 80px;
  }

  .liquor-slider .arrow .previous-arrow{
    margin-bottom: 280px;
  }

  .liquor-slider .category-container {
    position: relative;
    top: 5%;
    right: 20%;
  }
  .liquor-slider .category-container .line{
    width: 300px;
  }
  .liquor-slider .category-container .category {
    font-size: 50px;
    line-height: 33px;
  }
}

