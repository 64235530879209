.delivery-section {
    padding-top: 40px;
    padding-bottom: 156px;
}

.delivery-card-box {
    width: 100%;
    height: 146px;
    background: rgba(107, 116, 131, 0.1);
    border: 1.5px solid rgba(107, 116, 131, 0.3);
    backdrop-filter: blur(2px);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
    z-index: 999;
}

.uber-eats:hover {
    border: 1.5px solid #00C444;
    background-color: rgb(0, 196, 68, 0.05);
}
.doordash:hover {
    border: 1.5px solid #FF2F08;
    background-color: rgb(255, 47, 8, 0.05);
}
.skip-the-dishes:hover {
    border: 1.5px solid #FF7F00;
    background-color: rgb(255, 127, 0, 0.05);
}

.external-link-icon {
    width: 20px;
    height: 20px;
    /* margin-left: 10px;
    margin-top: 5px; */
    right: 12px;
    top: 12px;
    position: absolute;
}

.delivery-card-box img {
    width: 60%;
    height: auto;
}

@media screen and (max-width: 768px) {
    .delivery-section {
        padding-top: 20px;
        padding-bottom: 120px;
    }
}