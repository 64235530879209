.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 122px;
  background-color: var(--black-color);
  color: var(--white-color);
  
}

/* Default styles for larger screens */
.address-container,
.phone-number-container {
  display: flex;
  align-items: center;
}

.header .address-container .address,
.header .phone-number-container .phone-number {
  margin-left: 10px;
  /* font-size: small; */
  font-size: 14px;
  font-family: var(--font-primary);
  font-weight: 400;
}

/* Media query for smaller screens */
@media (max-width: 768px) {
  .header {
    display: none;
  }
}