.admin-sidebar {
    background: rgba(107, 116, 131, 0.05);
}

.admin-sidebar .admin-sidebar-top .admin-sidebar-logo {
    padding: 20px 30px 8px 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.admin-sidebar .admin-sidebar-top .admin-sidebar-logo img {
    height: 178px;
}

.admin-sidebar .admin-sidebar-top .admin-sidebar-breakline {
    color: #fff;
    border: 2px solid;
    margin: 0;
}

.admin-sidebar .admin-sidebar-bottom {
    padding: 20px 0;
}

/* .admin-sidebar .admin-sidebar-menu {
    padding: 16px 20px;
} */

.admin-sidebar .admin-sidebar-menu .admin-sidebar-btn {
    display: flex;
    align-items: center;
    justify-content: start;
    cursor:pointer;
    outline: none;
    border: none;
    font-size: 16px;
    font-family: var(--font-primary);
    font-weight: 600;
    gap: 10px;
    /* width: 100%; */
    border-radius: 8px;
    background: transparent;
    color: var(--black-color);

    padding: 14px 20px;
    margin: 8px 0px;
    transition: all 0.3s ease-in-out;
}

.admin-sidebar .admin-sidebar-menu .admin-sidebar-btn .admin-sidebar-btn-icon {
    color: var(--black-color);
    font-size: 18px;
}

.admin-sidebar .admin-sidebar-menu .admin-sidebar-btn:hover,
.admin-sidebar .admin-sidebar-menu .admin-sidebar-btn:hover .admin-sidebar-btn-icon {
    color: var(--hover-color);
}

.admin-sidebar .admin-sidebar-bottom img {
    height: 30px;
}

/* Mobile */
@media screen and (max-width: 768px) {
    .admin-sidebar .admin-sidebar-top .admin-sidebar-logo {
        padding: 0 30px;
    }
    .admin-sidebar .admin-sidebar-top .admin-sidebar-logo img {
        height: 136px;
    }
    .admin-sidebar .admin-sidebar-bottom {
        display: none;
    }
}