.daily-deals-container{
    margin-top: 120px;
    margin-bottom: 156px;
    position: relative;
}

.button-wrapper{
    display: flex;
    justify-content: space-between;
    align-items:center;
    position: absolute;
    top: 100px;
    margin-left: -50px;
    margin-right: -50px;
}

.button-wrapper .prev-icon{
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    color: rgb(133, 133, 133);
}

  .button-wrapper .prev-icon:hover{
    transform: scale(1.2);
    transition: transform 0.5s;
  }

  .button-wrapper .next-icon{
    font-size: 30px;
    line-height: 30px;
    text-align: center;
    color: rgb(133, 133, 133);
    transform: rotate(180deg);
  }

    .button-wrapper .next-icon:hover{
        transform: scale(1.2) rotate(180deg);
        transition: transform 0.5s;
    }

    .daily-deals-container .daily-deals-card-container-loading{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 20px;
    }

.daily-deals-container .daily-deals-card-container{
    padding: 0 180px;
}

.daily-deals-container .daily-deals-card-container .daily-deals-card-box{
    padding-left: 8px;
}

.daily-deals-container .background-image {
    display: flex;
    position: absolute;
    bottom: -20%;
    margin-left: -5%;
    z-index: 1;
}

.daily-deals-container .background-image img {
    width: 301px;
    height: 213.88px;
}

@media screen and (max-width: 768px) {
    .daily-deals-container .background-image, .daily-deals-container .button-wrapper{
        display: none;
    }
    .daily-deals-container{
        margin-top: 0;
        padding-top: 30px;
        margin-bottom: 0;
    }
    .daily-deals-container .daily-deals-card-container{
        padding: 0 20px;
    }
}

@media (min-width: 768px) and (max-width: 992px) {
    .daily-deals-container .background-image img{
       display: none;
    }
    .daily-deals-container{
        margin-top: 0;
    }
    .daily-deals-container .daily-deals-card-container{
        padding: 0 80px;
    }
    .button-wrapper{
        top: 70px;
    }
  }

  @media screen and (min-width: 992px) and (max-width: 1280px){
    .daily-deals-container{
        margin-top: 0;
    }
    .daily-deals-container .daily-deals-card-container{
        padding: 0 150px;
    }
    .button-wrapper{
        top: 80px;
    }
  }


