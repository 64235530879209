/* TestimonialCardStyles.css */
.testimonial-card {
    width: 777px;
    height: 380px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    border-radius: 100px 40px 100px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--white-color);
    transition: all 0.3s;
    overflow: hidden;
    position: relative;
  }
  
  .testimonial-card .testimonial-card-content {
    display: flex;
    flex-direction: column;
    position: absolute;
    margin-top: 50px;
  }
  
  .testimonial-card .testimonial-card-content .rating {
    margin-top: 15px;
    margin-bottom: 10px;
  }  
  
  .testimonial-card .testimonial-card-content .review {
    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    text-align: center;
    color: var(--gray-color);
    margin-top: 10px;
    margin-left: 90px;
    margin-right: 90px;
    margin-bottom: 40px;
  }
  
  .testimonial-card .testimonial-card-content .name {
    font-family: var(--font-heading); 
    font-style: normal; 
    font-weight: 700;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: var(--black-color);
  }
  
  .testimonial-card .testimonial-card-content .position {
    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 29px;
    text-align: center;
    color: rgba(107, 116, 131, 0.6);
  }

  .star{
    height: 16px;
    width: 16px;
    margin-right: 5px;
  }

  #empty{
    opacity: 0.5;
  }
  
@media screen and (max-width: 835px) and (min-width: 769px){
  .testimonial-card {
    width: 100%;
    /* height: 450px; */
  }

  .testimonial-card .testimonial-card-content {
    position: relative;
    top: 0;
  }

  .testimonial-card .testimonial-card-content .rating {
    margin-bottom: 0;
  }
  .testimonial-card .testimonial-card-content .review {
    margin: 50px 100px;
    margin-top: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .testimonial-card {
    box-shadow: none;
    border-radius: 0;
    height: 100%;
    background-color: transparent;
    width: 100%;
  }

  .testimonial-card .testimonial-card-content {
    position: relative;
    margin-top: -30px;
  }

  .testimonial-card .testimonial-card-content .rating {
    margin-top: 110px;
    margin-bottom: 0;
  }
  .testimonial-card .testimonial-card-content .review {
    margin: 20px 40px;
    margin-top: 10px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    text-align: center;
  }
}

