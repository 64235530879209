.map-container {
    padding-top: 196px;
    padding-bottom: 120px;
    overflow-x: hidden;
}

.map-container .backgroung-img1 img {
    height: 257px;
    width: 180px;
}

.map-container .backgroung-img1 {
    position: absolute;
    margin-top: 57px;
    right: 17%;
    z-index: -1;
}

.map-container .backgroung-img2 img {
    height: 166px;
    width: 310px;
}

.map-container .backgroung-img2 {
    margin-top: -120px;
    margin-left: 7%;
    z-index: -1;
}

.contact-backgroung-img3 img {
    width: 250px;
    height: 158px;
}

.contact-backgroung-img3 {
    position: absolute;
    margin-top: 0;
    /* margin-bottom: -56px; */
    /* margin-right: 7%; */
    right: -30px;
}

.contact-backgroung-img4 img {
    width: 300px;
    height: 214px;
}

.contact-backgroung-img4 {
    position: absolute;
    margin-top: -240px;
    /* margin-bottom: -56px; */
    /* margin-right: 7%; */
    left: -60px;
    z-index: -1;
}

.contact-google-map {
    padding-bottom: 5rem;
}

.contact-info-box, .opening-hours-box {
    padding: 0 3rem;
}

.contact-info-box .content-title, .opening-hours-box .content-title {
    font-weight: 700;
    font-size: 20px;
    text-transform: uppercase;
    color: #101A24;
    line-height: 40px;
}

.contact-info-box .content-info {
    font-weight: 400;
    font-size: 16px;
    color: #6B7483;
    /* line-height: 30px; */
}

.contact-info-box .content-info a {
    font-weight: 400;
    font-size: 16px;
    color: #6B7483;
    text-decoration: none;
    transition: all 0.3s ease-out;
    /* line-height: 30px; */
}
.contact-info-box .content-info a:hover {
    color: #FF9400;
}

.opening-hours-box .content-info {
    font-weight: 400;
    font-size: 16px;
    color: #6B7483;
    line-height: 30px;
    display: flex;
    flex-direction: row;
    gap: 2.5rem;
}

.opening-hours-box .content-info .hours {
    margin-top: -20px;
}

@media screen and (min-width: 992px) {
    .contact-info-box {
        padding-left: 5rem;
    }
    .opening-hours-box {
        padding-left: 12rem;
    }
}

@media screen and (max-width: 768px) {
    .map-container {
        padding-top: 120px;
        padding-bottom: 60px;
    }
    /* .map-container .backgroung-img1 {
        margin-top: 42px;
        right: -26%;
    } */
    .map-container .backgroung-img1, .map-container .backgroung-img2, .contact-backgroung-img3, .contact-backgroung-img4 {
        display: none;
    }
    .contact-info-box, .opening-hours-box {
        padding: 0 2rem;
    }
}