.table-container {
  border: none;
  padding-inline: 40px;
  padding-top: 20px;
  max-height: 540px;
  overflow-y: auto;
}

.table-container table{
  width: 100%;
  border-collapse: collapse;
  overflow: auto;
}

.table-container th,
.table-container td {
  border-bottom: 1px solid rgba(107, 116, 131, 0.20);
  padding: 8px;
  padding-right: 69px;
  color: var(--black, #101A24);
  font-size: 16px;
  font-family: var(--font-primary, Inter);
  font-weight: 500;
  line-height: 25px;
  text-align: left;
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  height: 50px;
}

/* Sticky header style */
.table-container thead th {
  top: 0;
  background-color: rgba(107, 116, 131, 0.20);
  z-index: 1; /* Ensure the header stays above the content */
}

/* Minimum height for each row to enable scrolling */
.table-container tbody tr {
  min-height: 50px;
}

.table-container .btn-group {
  display: flex;
  justify-content: start;
  align-items: center;
}

.table-container .btn-group button {
  padding: 5px 16px 5px 14px;
  border-radius: 8px;
  border: 1px solid var(--black-color);
  cursor: pointer;
  background-color: var(--white-color);
  color: var(--black-color);
  font-size: 16px;
  font-family: var(--font-primary, Inter);
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
}

.table-container .btn-group button:hover {
  background-color: var(--black, #101A24);
  color: var(--white, #FFFFFF);
}

.table-container .btn-group .edit-icon {
  font-size: 16px;
}

.table-container .btn-group .delete-icon {
  font-size: 16px;
}

.table-container #delete {
  border: 1px solid var(--red, #EB5757);
  color: var(--red, #EB5757);
  margin-left: 10px;
  transition: all 0.3s ease-in-out;
}

.table-container #delete:hover {
  background-color: var(--red, #EB5757);
  color: var(--white, #FFFFFF);
}

.table-container .title {
  width: 33%;
}

.table-container .price {
  width: 15%;
}

/* #dollar {
  display: none;
} */

@media only screen and (max-width: 768px) {
  .table-container {
    max-height: none; /* Disable the max-height for mobile devices */
    padding-inline: 5px;
  }

  .table-container .btn-group {
    display: flex;
    /* flex-direction: column; */
    justify-content: start;
    align-items: center;
    gap: 5px;
  }

  .table-container .btn-group #edit {
    padding-inline: 25px;
  }

  .table-container .btn-group button {
    font-size: 14px;
  }

  .table-container th,
  .table-container td {
    padding: 4px;
    padding-right: 10px;
    font-size: 14px;
    line-height: 20px;
    height: 68px;
  }

  .table-container th {
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 10px;
  }

  .table-container button {
    padding: 2px 8px;
    font-size: 14px;
    line-height: 20px;
  }

  .table-container .edit-icon {
    display: none;
  }

  .table-container .delete-icon {
    display: none;
  }

  #dollar {
    display: none;
  }

  .table-container #delete {
    margin-left: 0;
  }

  .table-container .title {
    width: 50%;
  }

  .table-container .price {
    width: 26%;
  }
}

@media only screen and (min-width: 1200px) {
  .table-container th,
  .table-container td {
    height: auto;
  }
}
