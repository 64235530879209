.footer-section {
  padding-top: 126px;
  padding-bottom: 40px;
}

.footer-topcontent-box .footer-company-grid .footer-company {
  padding-right: 56px;
}

.footer-topcontent-box .footer-company-grid .footer-company img {
  width: 304px;
  height: 114px;
  margin-top: -46px;
}

.footer-topcontent-box .footer-company-grid .footer-company p, .footer-topcontent-box .footer-company-grid .footer-company a {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  margin: 0;
  color: var(--gray-color);
  transition: all 0.3s ease-in-out;
}

.footer-topcontent-box .footer-company-grid .footer-opening-hours {
  padding-top: 36px;
}

.footer-topcontent-box .footer-company-grid .footer-opening-hours h3 {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 40px;
  text-transform: uppercase;
  margin-top: 0;
  margin-bottom: 8px;
  color: var(--black-color);
}

.footer-topcontent-box .footer-company-grid .footer-opening-hours p {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 29px;
  color: var(--gray-color);
  margin-top: 5px;
  margin-bottom: 0;
}

.footer-topcontent-box .footer-quicklink-grid h4, .footer-topcontent-box .footer-contact-grid h4, .footer-topcontent-box .footer-socialmedia-grid h4 {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 40px;
  margin: 0;
  text-transform: uppercase;
  color: var(--black-color);
}

.footer-topcontent-box .footer-quicklink-grid p, .footer-topcontent-box .footer-contact-grid p {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: var(--gray-color);
  margin: 0;
  transition: all 0.3s ease-in-out;
}

.footer-topcontent-box .footer-contact-grid a {
  text-decoration: none;
}

.footer-topcontent-box .footer-company-grid .footer-company a:hover, .footer-topcontent-box .footer-quicklink-grid .footer-link:hover, .footer-topcontent-box .footer-contact-grid .footer-link:hover {
  color: var(--hover-color);
  text-decoration: none;
}

.footer-topcontent-box .footer-socialmedia-grid .footer-social-icon img {
  height: 40px;
  width: 40px;
  transition: all 0.2s ease-in;
}

.footer-topcontent-box .footer-socialmedia-grid .footer-social-icon img:hover {
  opacity: 0.7;
}

.footer-section .footer-topcontent-box {
  padding-bottom: 42px;
}

.footer-section .footer-divider {
  width: 100%;
  height: 1.5px;
  background-color: var(--gray-color);
  border-radius: 5px;
  opacity: 0.2;
}

.footer-section .footer-copyrights {
  padding-top: 36px;
}

.footer-section .footer-copyrights p {
  font-family: var(--font-primary);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 29px;
  color: var(--gray-color);
  margin: 0;
  text-align: center;
}

.footer-section .footer-copyrights a {
  text-decoration: none;
  font-weight: 600;
  color: var(--gray-color);
  transition: all 0.3s ease-in-out;
}

.footer-section .footer-copyrights a:hover {
  color: var(--hover-color);
}

.footer-section .backgroung-img1 {
  position: absolute;
  margin-top: -380px;
  left: 33%;
  z-index: -1;
}

.footer-section .backgroung-img1 img {
  width: 736px;
  height: auto;
}


/* Media Query for the Responsiveness */

/* Mobile */
@media screen and (max-width: 768px) {
  .footer-section {
    padding-top: 90px;
    padding-bottom: 40px;
  }
  .footer-topcontent-box .footer-company-grid .footer-company {
    padding-right: 0;
  }
  .footer-topcontent-box .footer-quicklink-grid, .footer-topcontent-box .footer-contact-grid, .footer-topcontent-box .footer-socialmedia-grid {
    padding-top: 36px;
  }
  .footer-topcontent-box .footer-socialmedia-grid {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .footer-section .footer-copyrights p {
    font-size: 15px;
  }
  .footer-section .backgroung-img1 {
    display: none;
  }
}

/* Tablets */
@media (min-width: 768px) and (max-width: 992px) {
  .footer-section {
    padding-top: 120px;
    padding-bottom: 40px;
  }
  .footer-topcontent-box .footer-quicklink-grid, .footer-topcontent-box .footer-contact-grid, .footer-topcontent-box .footer-socialmedia-grid {
    padding-top: 36px;
    padding-right: 36px;
  }
  .footer-topcontent-box .footer-company-grid .footer-opening-hours {
    padding-top: 30px;
  }
  .footer-section .backgroung-img1 {
    display: none;
  }
}