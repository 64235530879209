.navLink{
    text-decoration: none;
    color: black;
}

.navBtnActive{
   color: black ;
}

.navBtnInActive{
    color: #6B7483;
}