.delicious-recipes-section {
    padding-top: 156px;
    padding-bottom: 156px;
    background-color: var(--primary-color);
}

.delicious-recipes-box .delicious-recipes-img {
    z-index: -1;
}

.delicious-recipes-box .delicious-recipes-img .img {
    /* width: 330px; */
    width: 21.5vw;
    height: auto;
}

.delicious-recipes-box .delicious-recipes-content {
    /* width: 512px; */
    width: 43%;
    height: auto;
    margin-right: -6vw;
    margin-top: -8px;
    background: var(--white-color);
    box-shadow: 0px 4px 25px rgba(107, 116, 131, 0.15);
    border-radius: 10px;
    z-index: 1;
}

.delicious-recipes-box .delicious-recipes-content .section-heading .subtitle {
    margin-top: 0;
}
.delicious-recipes-box .delicious-recipes-content .section-heading .title {
    margin-bottom: 0;
    margin-top: 44px;
}

.delicious-recipes-box .delicious-recipes-content .delicious-recipes-text {
    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    text-align: center;
    color: var(--gray-color);
    padding-top: 34px;
    margin: 0;
}

.delicious-recipes-box .delicious-recipes-content .delicious-recipes-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--secondary-color);
    border-bottom: 3px solid var(--secondary-color);
    border-radius: 2px;
    cursor: pointer;
    padding-top: 40px;
    transition: all 0.3s ease-in-out;
    width: fit-content;
}

.delicious-recipes-box .delicious-recipes-content .delicious-recipes-btn p {
    font-family: var(--font-primary);
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    letter-spacing: 0.01em;
    color: var(--secondary-color);
    text-align: center;
    text-decoration: none;
    margin: 0;
    transition: all 0.3s ease-in-out;
}

.delicious-recipes-box .delicious-recipes-content .delicious-recipes-btn:hover {
    border-bottom: 3px solid var(--hover-color);
}
.delicious-recipes-box .delicious-recipes-content .delicious-recipes-btn:hover p {
    color: var(--hover-color);
}

/* Background images */
.delicious-recipes-section .backgroung-img1 {
    position: absolute;
    margin-top: -530px;
    right: 10%;
    z-index: -1;
}

.delicious-recipes-section .backgroung-img1 img {
    width: 224px;
    height: auto;
}

/*  */
.delicious-recipes-section .backgroung-img2 {
    position: absolute;
    margin-top: 517px;
    left: 8%;
    z-index: -1;
}

.delicious-recipes-section .backgroung-img2 img {
    width: 276px;
    height: auto;
    transform: rotate(180deg);
}

/* Media Query for the Responsiveness */

/* Mobile */
@media screen and (max-width: 768px) {
    .delicious-recipes-section {
        padding-top: 90px;
        padding-bottom: 60px;
    }
    .delicious-recipes-box .delicious-recipes-img, .delicious-recipes-section .backgroung-img1, .delicious-recipes-section .backgroung-img2 {
        display: none;
    }
    .delicious-recipes-box .delicious-recipes-content {
        width: 100%;
        height: auto;
        margin: 0;
        background: none;
        box-shadow: none;
    }
    .delicious-recipes-box .delicious-recipes-content .section-heading .title {
        margin-top: 24px;
    }
    .delicious-recipes-box .delicious-recipes-content .delicious-recipes-text {
        padding-top: 30px;
        font-size: 14px;
        line-height: 25px;
    }
    .delicious-recipes-box .delicious-recipes-content .delicious-recipes-btn {
        padding-top: 30px;
    }
    .delicious-recipes-box .delicious-recipes-content .delicious-recipes-btn p {
        font-size: 18px;
    }
}

/* Tablets */
@media (min-width: 768px) and (max-width: 992px) {
    .delicious-recipes-section {
        padding-top: 128px;
        padding-bottom: 128px;
    }
    .delicious-recipes-box .delicious-recipes-img, .delicious-recipes-section .backgroung-img1 {
        display: none;
    }
    .delicious-recipes-box .delicious-recipes-content {
        width: 100%;
        height: auto;
        margin: 0;
    }
    .delicious-recipes-section .backgroung-img2 {
        margin-bottom: -98px;
        margin-left: -5%;
    }
}

/* Small Laptop & Desktop */
@media (min-width: 992px) and (max-width: 1280px) {
    .delicious-recipes-box .delicious-recipes-img .img {
        width: 260px;
        height: auto;
    }
    .delicious-recipes-section .backgroung-img1 {
        margin-top: -421px;
        margin-left: -5%;
        right: 12%;
    }
}