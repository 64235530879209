.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.overlay .form {
  width: 500px;
  background-color: white;
  padding: 30px 40px;
  border-radius: 5px;
}

.overlay .form .error-message{
  color: red;
}

.overlay .form .overlay-title{
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 20px;
}

.overlay .form label {
  display: flex;
  margin-bottom: 10px;
  flex-direction: column;
}

.overlay .form input{
  /* width: 100%; */
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid var(--gray-color);
  color: var(--gray-color);
  border-radius: 5px;
  outline: none;
  font: var(--font-primary);
}

.overlay .form textarea {
  /* width: 100%; */
  height: 80px;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid var(--gray-color);
  color: var(--gray-color);
  border-radius: 5px;
  outline: none;
  resize: none;
  font: var(--font-primary);
}

.overlay .form .category-dropdown{
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid var(--gray-color);
  border-radius: 5px;
  outline: none;
  font: var(--font-primary);
  color: var(--black-color);
  background: var(--white-color);
}

.overlay .form input:hover,
.overlay .form textarea:hover{
  border: 1.2px solid var(--black-color);
}

.overlay .form .upload-image-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.overlay .form .upload-image-container .image-preview{
  width: 100%;
  height: 80px;
  margin-bottom: 10px;
  border-radius: 5px;
  outline: none;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.overlay .form .upload-image-container .image-preview img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
}

.overlay .form .upload-image-container label {
  gap: 10px;
  flex-direction: row;
}

.overlay .form .upload-image-container #upload-button {
  display: flex;
  height: 30px;
  padding: 5px;
  border: none;
  border-radius: 5px;
  outline: none;
  font: var(--font-primary);
  background-color: var(--gray-color);
  color: var(--white-color);
  cursor: pointer;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.overlay .form .upload-image-container #upload-button:hover {
  background-color: var(--black-color);
}

.overlay .form .dailydeals-dropdown {
  width: 100%;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid var(--gray-color);
  border-radius: 5px;
  outline: none;
  font: var(--font-primary);
  color: var(--black-color);
  background: var(--white-color);
}

.overlay .form .special-dish-label {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.overlay .form .special-dish-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.overlay .form .special-dish-toggle {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.overlay .form .special-dish-checkbox {
  display: none;
}

.overlay .form .toggle-button {
  width: 50px;
  height: 24px;
  border-radius: 12px;
  background-color: #ccc;
  position: relative;
  margin-left: 10px;
  margin-top: -5px;
  transition: background-color 0.3s ease;
}

.overlay .form .toggle-button.active {
  background-color: var(--secondary-color);
}

.overlay .form .toggle-button::before {
  content: "";
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 2.5px;
  left: 3px;
  transition: transform 0.3s ease;
}

.overlay .form .toggle-button.active::before {
  transform: translateX(25px);
}

.overlay .form .button-group{
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  gap: 16px;
  margin-top: 36px;
  margin-bottom: 10px;
}
.overlay .form .submit{
  padding: 10px 30px;
  border: none;
  border-radius: 8px;
  background-color: var(--secondary-color);
  color: var(--white-color);
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.overlay .form .cancel{
  padding: 10px 30px;
  background-color: var(--white-color);
  color: var(--black-color);
  border: 1px solid rgba(0, 0, 0, 0.322);
  border-radius: 8px;
  font-size: 16px;
  font-family: Inter;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
}

.overlay .form .submit:hover{
  background-color: var(--hover-color);
}

.overlay .form .cancel:hover{
  background-color: var(--gray-color);
  color: var(--white-color);
}